<template>
  <div id='login-screen'>
    <div class="container webshop-container">
      <div class="content-wrapper">
        <mobile-user-auth-tabs />
        <h1 class="title">{{ $t('pages.login.title') }}</h1>
        <h4 class="subtitle">{{ $t('pages.login.subtitle') }}</h4>

        <error-toast
          :isVisible="errorToast.isVisible"
          :text="errorToast.text"
        />

        <div class="inputs-wrapper">
          <base-input
            :type="emailInput.type"
            :hasError="emailInput.hasError"
            :placeholderText="$t(emailInput.placeholder)"
            @input="handleInput('email', ...arguments)"
          />
          <base-input
            :type="passwordInput.type"
            :hasError="passwordInput.hasError"
            :placeholderText="$t(passwordInput.placeholder)"
            @input="handleInput('password', ...arguments)"
          />
          <router-link class="forgotten-link" to="/users/request-password-reset">
            {{ $t('pages.login.forgottenPassword') }}
          </router-link>
        </div>

        <div class="buttons-wrapper">
          <base-button
            :text="$t('pages.login.buttons.login')"
            @clicked="handleLoginBtnClick"
          />
          <span class="registration-label">{{ $t('pages.login.registrationLabel') }}</span>
          <base-button
            :isSecondary="true"
            :text="$t('pages.login.buttons.registration')"
            @clicked="handleRegistrationBtnClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'LoginScreen',
  props: {},
  components: {
    BaseInput,
    BaseButton,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
    'mobile-user-auth-tabs': () => import('@/components/shared/elements/partials/MobileUserAuthTabs'),
  },
  data: () => ({
    emailInput: {
      type: 'email',
      hasError: false,
      placeholder: 'pages.login.form.inputs.email',
      value: undefined,
    },
    passwordInput: {
      type: 'password',
      hasError: false,
      placeholder: 'pages.login.form.inputs.password',
      value: undefined,
    },
    errorToast: {
      isVisible: false,
      text: undefined,
    },
  }),
  created() {
    if (this.getSavedLoginError !== undefined) {
      this.errorToast.isVisible = true;
      this.errorToast.text = this.getSavedLoginError;
    }

    document.addEventListener('keydown', this.handleKeyboardAction);
  },
  computed: {
    ...mapGetters({
      getSavedLoginError: 'users/getSavedLoginError',
      getCurrentCart: 'cart/getCurrentCart',
    }),
  },
  methods: {
    ...mapActions({
      login: 'users/login',
    }),
    handleKeyboardAction(e) {
      if (e.key === 'Enter') {
        this.handleLoginBtnClick();
      }
    },
    handleInput(key, value) {
      switch (key) {
        case 'email':
          this.emailInput.value = value;
          break;
        case 'password':
          this.passwordInput.value = value;
          break;
        default:
          break;
      }
    },
    validateInputs() {
      if (this.emailInput.value === undefined || this.emailInput.value.length === 0) {
        this.emailInput.hasError = true;
      } else {
        this.emailInput.hasError = false;
      }

      if (this.passwordInput.value === undefined || this.passwordInput.value.length === 0) {
        this.passwordInput.hasError = true;
      } else {
        this.passwordInput.hasError = false;
      }
    },
    handleLoginBtnClick() {
      this.validateInputs();

      if (!this.emailInput.hasError && !this.passwordInput.hasError) {
        const requestObj = {
          email: this.emailInput.value,
          password: this.passwordInput.value,
        };

        if (this.getCurrentCart !== undefined) {
          requestObj.cart = this.getCurrentCart._id;
        }

        this.login(requestObj)
          .then(() => this.$router.push('/'))
          .catch((error) => {
            this.errorToast.isVisible = true;
            this.errorToast.text = error.data.message;
          });
      }
    },
    handleRegistrationBtnClick() {
      this.$router.push('/users/registration');
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyboardAction);
  },
};
</script>

<style lang='scss' scoped>
$inputsWidth: 210px;

@keyframes fadeInDown {
  0% {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }
  100% {
    overflow: hidden;
    max-height: 150px;
    opacity: 1;
  }
}

.error-toast-transition-enter-active {
  animation: fadeInDown .8s;
}

.error-toast-transition-leave-active {
  animation: fadeInDown .8s reverse;
}

#login-screen {
  padding: 85px 0 280px;
}

.content-wrapper {
  max-width: 420px;
  background-color: $white;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  margin: 0 auto;
  padding: 36px 34px 44px;

  @media screen and (max-width: $breakpointDownSm) {
    padding: 0 40px 40px;
  }
}

.title {
  @include titleMixin;

  @media screen and (max-width: $breakpointDownSm) {
    display: none;
  }
}

.subtitle {
  @include subtitle;
  margin: 0 0 20px;

  @media screen and (max-width: $breakpointDownSm) {
    font-size: 14px;
    line-height: 17px;
  }
}

.inputs-wrapper,
.buttons-wrapper {
  width: $inputsWidth;
  margin: 0 auto;

  @media screen and (max-width: $breakpointDownSm) {
    width: 100%;
  }
}

.inputs-wrapper {
  margin-bottom: 35px;

  .base-input {
    &:first-child {
      margin: 0 0 10px;
    }
  }
}

.buttons-wrapper {
  button {
    display: block;
    width: 100%;

    @media screen and (max-width: $breakpointDownSm) {
      line-height: 45px;
    }
  }
}

.forgotten-link {
  display: block;
  text-align: center;
  font-size: 12px;
  margin: 3px 0 0;
}

.registration-label {
  display: block;
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  margin: 12px 0;
}

.error-toast {
  margin: 0 0 40px;
}

.mobile-user-auth-tabs {
  display: none;

  @media screen and (max-width: $breakpointDownSm) {
    display: block;
    margin: 0 auto 23px;
  }
}
</style>
